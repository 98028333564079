<template>
    <div class="col-right">
        <a-spin :spinning="spinning">
            <a-tabs default-active-key="1">

                <a-tab-pane key="1" tab="模版下单" force-render>
                    <a-card>
                        <a-steps :current="currentStep">
                            <a-step title="上传下单模版"/>
                            <a-step title="下单模版商品预览"/>
                            <a-step title="下单成功"/>
                        </a-steps>
                        <div v-if="currentStep == 0" class="section1">
                            <div
                                    class="box-bg"><p>模板下单目前仅支持预付款支付，暂不支持在线支付。为提高操作的成功率：</p>
                                <p>1、按模板要求填写下单商品信息和收件信息；</p>
                                <p>2、数据最终只更新有效状态数据；</p>
                                <p> 3、最大处理行数500条； <a target="_blank"
                                                     href="https://cdn.myhbei.com/%E5%88%86%E9%94%80%E5%95%86%E5%BF%AB%E9%80%9F%E4%B8%8B%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx">模板下载</a>
                                </p>
                            </div>
                            <div class="box">
                                <div class="clearfix">
                                    <a-upload :file-list="fileList" :remove="handleRemove"
                                              :before-upload="beforeUpload">
                                        <a-button>
                                            <a-icon type="upload"/>
                                            点击上传
                                        </a-button>
                                    </a-upload>
                                    <a-button
                                            type="primary"
                                            :disabled="fileList.length === 0"
                                            :loading="uploading"
                                            style="margin-top: 16px"
                                            @click="handleUpload"
                                    >
                                        {{ uploading ? 'Uploading' : '下一步' }}
                                    </a-button>
                                </div>
                            </div>
                        </div>
                        <div v-if="currentStep == 1" class="section2">
                            <div class="section2-box" v-if="fileRes.absent.length + fileRes.exists_order_spu.length > 0">
                                <p v-for="(item, idx) in fileRes.absent" :key="idx">商品编号 {{item}}
                                    问题：商品编码有误或分销商选品库数据错误；</p>
                                <p v-for="(item, idx) in fileRes.exists_order_spu" :key="idx">商品编号 {{item}}
                                    问题：文件中的外部订单号已重复商品编码有误或分销商选品库数据错误；</p>
                            </div>

                            <template v-for="(goodsList, idx) in fileRes.success_list">
                                <a-table :columns="successColumn" :data-source="goodsList" :pagination="false" bordered class="st" style="margin: 1rem 0px;" :key="idx">
                                    <div slot="goods" slot-scope="text, record">
                                        <span><div class="order-goods ng-star-inserted"><div class="order-goods-img"><img
                                                :src="record.logo"></div><div
                                                class="order-goods-info"><h2><span>{{record.title}}<br><span
                                                class="order-goods-info-spec"> 规格：<span
                                                class="ng-star-inserted">{{record.sku_key}}</span><br> 规格编号：{{record.sku}}</span></span></h2></div></div></span>
                                    </div>
                                    <div slot="base_price" slot-scope="text, record">
                                        {{record.base_price}}
                                    </div>
                                    <template slot="footer" slot-scope="record">
                                        <div class="footer-box ng-star-inserted"><div><p>收货人：{{record[0].spu.express_name}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 联系电话：{{record[0].spu.express_mobile}}</p><p>详细地址：{{record[0].spu.express_area}}{{record[0].spu.express_address}}</p></div><div><p>总计</p><em>¥{{record[0].goods_amount}}</em></div></div>
                                    </template>
                                </a-table>
                            </template>
                            <div class="center">
                                <a-button @click="cancelOrder">放弃下单</a-button>
                                <a-button type="primary" style="margin-left: 8px;" :disabled="fileRes.success_list.length == 0" @click="submitOrder">
                                    <span>有效总额：</span>
                                    <span>￥{{fileRes.total_amount}}</span>
                                    <span>，确认下单</span>
                                </a-button>
                            </div>
                        </div>
                        <div v-if="currentStep == 2" class="section3">
                            <a-result v-if="orderRes.code == 200" status="success" title="下单成功">
                                <template #extra>
                                    <a-button key="console" type="primary" @click="cancelOrder">
                                        继续下单
                                    </a-button>
                                </template>
                            </a-result>
                            <a-result v-else status="error" title="下单失败"
                                      :sub-title="orderRes.msg"
                            >
                                <template #extra>
                                    <a-button key="console" type="primary" @click="cancelOrder">
                                        重新下单
                                    </a-button>
                                </template>
                            </a-result>
                        </div>
                    </a-card>
                </a-tab-pane>
            </a-tabs>
        </a-spin>
    </div>
</template>

<script>
    import service from "../../../utils/request";

    const columns = [
        {
            key: 1,
            title: '商品',
            dataIndex: 'title',
        },
        {
            key: 2,
            title: '单价(元)',
            dataIndex: 'price',
        },
        {
            key: 3,
            title: '数量',
            dataIndex: 'quantity',
            scopedSlots: {customRender: 'quantity'},
        },
        {
            key: 4,
            title: '小计(元)',
            dataIndex: 'amount',
            scopedSlots: {customRender: 'amount'},
        },
    ];
    export default {
        name: "quick-order",
        data() {
            return {
                uploadUrl: '',
                currentStep: 0,
                spinning: false,
                fileList: [],
                columns,
                selectGoods: [
                    {
                        title: "开丽婴儿燕麦护肤套装 润肤乳 面霜",
                        price: "38.95",
                        quantity: 3,
                    },
                    {
                        title: "开丽婴儿燕麦护肤套装 润肤乳 面霜",
                        price: "38.95",
                        quantity: 3,
                    }
                ],

                selectedRowKeys: [], // Check here to configure the default column,
                goodsSelected: [],
                file: '',
                uploadHeader: {},
                uploading: false,
                successColumn: [
                    {title: '商品', dataIndex: 'goods', scopedSlots: {customRender: 'goods'}},
                    {title: '单价(元)', dataIndex: 'base_price', scopedSlots: {customRender: 'base_price'}},
                    {title: '数量', dataIndex: 'number_goods'},
                    {title: '小计', dataIndex: 'goods_amount'},
                ],
                fileRes: {
                    absent:[],
                    exists_order_spu:[],
                    success_list: []
                },
                orderRes:{
                    code:0,
                    msg: ''
                },
            }
        },
        created() {
            this.uploadUrl = this.$apiBaseURL + '/supplier/po' + service.uri.order.quickOrderFile;
            let userInfo = JSON.parse(localStorage.getItem('userInfo'));
            this.uploadHeader = {
                tenantId: userInfo.tid,
                token: userInfo.token
            }
        },
        methods: {
            changeUpload(e) {
                if (e.file.status === 'done') {
                    // Get this url from response in real world.
                    let res = e.file.response;
                    console.log(res)
                    if (res.code == 1) {
                        this.file = res.data.url;
                    }
                }
            },
            handleRemove(file) {
                const index = this.fileList.indexOf(file);
                const newFileList = this.fileList.slice();
                newFileList.splice(index, 1);
                this.fileList = newFileList;
            },
            beforeUpload(file) {
                this.fileList = [...this.fileList, file];
                return false;
            },
            handleUpload() {
                const {fileList} = this;
                const formData = new FormData();
                fileList.forEach(file => {
                    formData.append('file', file);
                });
                this.uploading = false;
                this.spinning = false;

                // You can use any AJAX library you like
                service.post(service.uri.order.quickOrderFile, formData).then(res => {
                    this.uploading = false;
                    this.spinning = false;
                    if (res.code == 200) {
                        this.currentStep = 1;
                        this.fileRes = res.data;
                    }
                })
            },
            submitOrder(){
                this.spinning = true;
                service.post(service.uri.order.quickOrder, {goodsList: this.fileRes.success_list}).then(res => {
                    this.spinning = false;
                    this.currentStep = 2;
                    if (res.code == 200) {
                        this.orderRes.code = 200;
                    } else {
                        this.orderRes.msg = res.info;
                    }
                })
            },
            onSelectChange(selectedRowKeys) {
                console.log('selectedRowKeys changed: ', selectedRowKeys);
                this.selectedRowKeys = selectedRowKeys;
            },
            deleteGoods(idx) {

                console.log(idx);
            },
            onSearch() {
            },
            quantityMinus(idx) {
                if (this.selectGoods[idx].quantity <= 1) return;
                this.selectGoods[idx].quantity -= 1;
            },
            quantityPlus(idx) {
                let goods = this.selectGoods[idx];
                goods.quantity += 1;
                this.selectGoods[idx] = goods;
            },
            cancelOrder() {
                this.file = '';
                this.fileList = [];
                this.fileRes = {
                    absent:[],
                    exists_order_spu:[],
                    success_list: []
                };
                this.currentStep = 0;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/css/common";

    .box-bg {
        margin-top: 30px;
        padding: 20px;
        background-color: #fafafa;

    }

    .order-goods {
        display: flex;
        text-align: left;

        .order-goods-img {
            width: 80px;
            height: 80px;
            margin-right: 10px;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        .order-goods-info {
            flex: 1;
            h2 {
                font-size: 16px;
            }
            .order-goods-info-spec {
                margin-top: 8px;
                color: #9b9b9b;
                font-size: 12px;
            }
        }
    }

    .footer-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 100px 16px 16px;
        p {
            margin-bottom: 6px;
        }
        em {
            color: #de0000;
            font-size: 16px;
            font-style: normal;
        }
    }
    .section2 {
        .section2-box {
            margin-top: 20px;
            padding: 16px;
            color: #fff;
            background-color: #d9001b;

        }

        .center {
            margin-top: 20px;
        }
    }
    .section3{
        margin-top: 20px;
    }

    .box {
        padding: 100px 100px 20px;
        text-align: center;

        .sf {
            display: block;
        }
    }

    .center {
        text-align: center;
    }

    p {
        font-size: 14px;
        margin-bottom: 1em;
    }

    .goods-list-wrap {
        margin: 20px auto 0;
    }

    .ant-card {

        .ant-card-body {
            padding: 10px 10px 0;
        }

        ul.ant-card-actions {
            background: #fff !important;

            .action-box {
                font-size: 14px;
                color: $defaultColor;
            }
        }
    }

    .ant-table {

        div.ant-table-footer {
            padding: 0 !important;
        }
    }

    .ant-tabs-bar {
        margin: 0 0 16px;
        border-bottom: 1px solid #f0f0f0;
        outline: none;
    }

    .good-price {
        display: flex;
        align-items: baseline;
        color: $defaultColor;

        span {
            font-size: 14px;
            height: 28px;
            line-height: 28px;
            margin-right: 2px;
        }

        strong {
            font-size: 20px;
            font-weight: 400;
            height: 28px;
            line-height: 28px;
        }
    }

    .gross-profit {
        height: 17px;
        line-height: 17px;
        color: $defaultColor;
        font-size: 12px;
        font-weight: 400;
    }

    .good-desc {
        margin: 8px 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
        color: #333;
        font-size: 13px;
        line-height: 18px;
        height: 36px;
    }

    .good-other {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #999;
        height: 17px;
        line-height: 17px;

        .left {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 83px;
        }
    }


</style>